.guess-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
  }
  
  .guess-row.active {
    padding: .5rem;
  }
  
  .row-content {
    display: flex;
    align-items: center;
  }
  
  .pegs {
    display: flex;
  }
  
  .peg {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
  }
  
  .feedback {
    display: flex;
    margin-left: 1rem;
  }
  
  .feedback-peg {
    width: 1rem;
    height: 1rem;
    margin: 0 0.2rem;
    border-radius: 50%;
    border: 1px solid #000;
  }
  
  .feedback-peg.correct {
    background-color: black;
  }
  
  .feedback-peg.wrong-place {
    background-color: white;
  }
  
  .feedback-peg.empty {
    background-color: gray;
  }
  
  .guess-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  