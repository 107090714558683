.navbar {
  background-color: black;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 1% 20px;
}

.nav-name-container {
  flex: 0 0 15%; /* Take up 15% of navbar width */
}

.nav-name {
  color: #e1e1e1;
  font-weight: 800;
  font-size: 18px;
  text-align: left;
}

.nav-links {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  list-style-type: none;
  padding: 0 15%;
  margin: 0;
}

.nav-links li {
  flex: 1; /* Make each li take equal space */
}

.nav-links li span {
  display: flex;
  justify-content: center; /* Center the link text */
}

.navbar li span a {
  font-size: 18px;
  color: #e1e1e1;
  font-family: inherit;
  font-weight: 800;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
  position: relative; /* Required for ::after positioning */
}

/* Hover color change */
.navbar li span a:hover,
.navbar li span a:focus {
  color: #fff;
}

/* Underline animation */
.navbar li span a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0%; /* Start with a width of 0% */
  height: 2px;
  background-color: #fff;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1), left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translateX(-50%);
}

.navbar li span a:hover::after {
  width: 100%; /* Expand to full width on hover */
  left: 50%;
}

/* Style for the icons on the right */
.icons-container {
  display: flex;
  gap: 15px;
  align-items: center;
  flex: 0 0 15%; /* Take up 15% of navbar width */
  justify-content: flex-end; /* Align icons to the right */
}
