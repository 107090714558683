.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #ffffff00;
    width: 70%;
    border-radius: 8px;
    position: relative;
    max-height: 95vh;
    overflow-y: auto; /* Makes modal content scrollable */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.resume-content img{
    padding-top: 20px;
    border-radius: 10%;
    width:100%;
    height:100%;
}

.modal-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.modal-content {
    scrollbar-width: none;
}