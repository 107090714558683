.contact-form-container {
    max-width: 500px;
    margin: 5% auto;
    padding: 2em;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

.form-group {
    margin-bottom: 1em;
}

label {
    display: block;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5em;
}

input, textarea {
    width: 100%;
    padding: 0.5em;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

button {
    width: 100%;
    padding: 0.75em;
    font-size: 1em;
    color: #fff;
    background-color: #4d4d4d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #232430;
}

.error-message {
    color: #f25858;
    font-size: 0.9em;
}

.success-message {
    color: #58f258;
    font-size: 1em;
    text-align: center;
    margin-top: 1em;
}
