

.header-container{
    display: flex;
    flex-direction: row;
}
.header{
    width: 70%;
    display: flex;
    flex-direction: column;
}
.detail-title{
    margin:0em;
    padding-left: 10%;
    padding-top:1em;
    font-size: 2em;
    text-align: left;
}
.detail-date{
    padding-left:10%;  
    padding-top:.6em;  
    margin:0%;
    font-weight: 400;
}
.header-links{
    width:30%;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap:2em;
    padding-left:15%;
    padding-top:1em;
}

.header-links a img{
    width:2.5rem;
    height: 2.5rem;

}
.header-container a img:hover{
transform: scale(1.1);
}

.icon-disabled {
    opacity: 0.0;      /* Make the image partially transparent */
    cursor: default;   /* Change cursor to default */
    pointer-events: none; /* Disable interactions */
}

detail-view{
    border-radius:1.8em;
}
.detail-view {
    /* Optional: Add smooth scrolling */
    scroll-behavior: smooth;
    overflow-y: auto; /* Ensure vertical scrolling */
    padding-right: 10px;
}

/* Customize scrollbar appearance */
.detail-view::-webkit-scrollbar {
    width: 10px !important; /* Adjust scrollbar width */
    background-color: rgba(0, 0, 0, 0) !important;

}

.detail-view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px !important;
    margin-right: -10px !important;
}

.detail-parent{
    display: flex;
}

.detail-img-container{
    width:55%;
    padding: 2.2em 2em 2em 3.6em;
    border-radius: 15%;
}
.detail-img{
    max-width: 100%;
    border-radius: 8px;
}

.detail-description{
    padding: 1em 1em;
}

.detail-container{
    width:45%;
    display: flex;
    flex-direction: column;
}

.skills-container {
    padding: 0em 1em;
}

.skills-container h3 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
    color: #333;
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em; /* Space between skill boxes */
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.skill-item {
    background-color: #e0e0e0; /* Light gray background */
    color: #333;
    padding: 0.4em 0.8em;
    border-radius: 8px; /* Rounded corners */
    font-size: 0.9em;
    white-space: nowrap; /* Prevents text from wrapping to next line */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for effect */
}

/* Container for the image gallery */
.img-gallery {
    display: flex;
    flex-direction: column;  /* Stack images vertically */
    align-items: center;     /* Center images horizontally */
    gap: 1em;                /* Space between images */
    width: 100%;             /* Full width of container */
    padding: 1em 0;          /* Padding at top and bottom */
}

/* Individual images in the gallery */
.one-gallery-img {
    width: 90%;              /* Each image takes up 90% of the container width */
    max-width: 600px;        /* Optional max width for larger screens */
    border-radius: 8px;      /* Slight border radius for styling */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
